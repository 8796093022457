<template>
    <div class="personalReport-wrap">
        <van-nav-bar
            title="个人报表"
            left-arrow
            fixed
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="personalReport-main-wrap">
            <div class="date-box" @click="showDateCalendar=true">
                <div class="date-item-box">
                    <van-image :src="themeImgs.commonCalendarIcon" />
                    <span class="date">{{startDate}}</span>
                    <van-image :src="themeImgs.commonArrowDownIcon" />
                </div>
                <p>至</p>
                <div class="date-item-box">
                    <van-image :src="themeImgs.commonCalendarIcon" />
                    <span class="date">{{endDate}}</span>
                    <van-image :src="themeImgs.commonArrowDownIcon" />
                </div>
            </div>
            <div class="report-list-wrap">
                <div class="list-item-box">
                    <div class="item-box">
                        <span class="label">买单总额</span>
                        <span class="amount">{{reportData.buy_amount}}币</span>
                    </div>
                    <div class="slider"></div>
                </div>
                <div class="list-item-box">
                    <div class="item-box">
                        <span class="label">卖单总额</span>
                        <span class="amount">{{reportData.sell_amount}}币</span>
                    </div>
                    <div class="slider"></div>
                </div>
                <div class="list-item-box">
                    <div class="item-box">
                        <span class="label">API充值总额</span>
                        <span class="amount">{{reportData.recharge_amount}}币</span>
                    </div>
                    <div class="slider"></div>
                </div>
                <div class="list-item-box">
                    <div class="item-box">
                        <span class="label">API充值返点</span>
                        <span class="amount">{{reportData.recharge_rebate}}币</span>
                    </div>
                    <div class="slider"></div>
                </div>
                <div class="list-item-box">
                    <div class="item-box">
                        <span class="label">API提现总额</span>
                        <span class="amount">{{reportData.withdraw_amount}}币</span>
                    </div>
                    <div class="slider"></div>
                </div>
                <div class="list-item-box">
                    <div class="item-box">
                        <span class="label">交易纠纷订单总额</span>
                        <span class="amount">{{reportData.dispute_amount}}币</span>
                    </div>
                    <div class="slider"></div>
                </div>
                <div class="list-item-box">
                    <div class="item-box">
                        <span class="label">转账总额</span>
                        <span class="amount">{{reportData.transfer_out}}币</span>
                    </div>
                    <div class="slider"></div>
                </div>
                <div class="list-item-box">
                    <div class="item-box">
                        <span class="label">收款总额</span>
                        <span class="amount">{{reportData.transfer_in}}币</span>
                    </div>
                    <div class="slider"></div>
                </div>
                <!--    比特币充值/提现    start   -->
                <div class="list-item-box">
                    <div class="item-box">
                        <span class="label">USDT充值</span>
                        <span class="amount">{{reportData.bitcoin}}币</span>
                    </div>
                    <div class="slider"></div>
                </div>
                <div class="list-item-box">
                    <div class="item-box">
                        <span class="label">USDT提现</span>
                        <span class="amount">{{reportData.bitcoin_withdraw}}币</span>
                    </div>
                    <div class="slider"></div>
                </div>
                <!--    比特币充值/提现    end   -->
            </div>
        </div>
        <!-- 日历插件 -->
        <van-calendar v-model:show="showDateCalendar" type="range" allow-same-day :show-confirm="false" :min-date="minDate" :max-date="maxDate" @confirm="handleGetStartEndDate" />
    </div>
</template>

<script>
import { ref,onMounted,computed } from 'vue'
import {getReport} from '@/api/api'
import moment from 'moment'
import { useStore } from "vuex";

export default {
    setup () {
        const store = useStore();
        // 点击导航栏左侧返回键/取消按钮
        const onClickLeft = () => {
            history.back()
        }
        // 请求数据接口
        const handleGetReport = () => {
            let params = {}
            params.start = `${startDate.value} 00:00:00`
            params.end = `${endDate.value} 23:59:59`
            getReport(params).then(res => {
                if(res.result == 200000) {
                    reportData.value = res.data.list
                }
            })
        }
        onMounted(() => {
            handleGetReport()
        })
        // 存储内容
        const reportData = ref('')
        // 是否显示日历插件
        const showDateCalendar = ref(false)
        // 存储开始日期
        const startDate = ref(moment().format("YYYY-MM-DD"))
        // 存储结束日期
        const endDate = ref(moment().format("YYYY-MM-DD"))
        // 日期组件的最小日期--半年前
        const minDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
        // 日期组件的最大日期--今天
        const maxDate = new Date()
        // 选择日期区间后
        const handleGetStartEndDate = (date) => {
            const [start, end] = date
            startDate.value = moment(start).format("YYYY-MM-DD")
            endDate.value = moment(end).format("YYYY-MM-DD")
            showDateCalendar.value = false
            handleGetReport()
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            onClickLeft,
            reportData,
            showDateCalendar,
            startDate,
            endDate,
            minDate,
            maxDate,
            handleGetStartEndDate,
            themeImgs
        }
    }
}
</script>

<style lang="less" scoped>
.personalReport-wrap {
    .personalReport-main-wrap {
        min-height: calc(100vh - 46px);
        margin-top: 46px;
        padding: 16px 12px;
        background: var(--mainBgColor);
        .date-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 36px;
            padding: 0 20px;
            background: var(--boxBgColor1);
            border-radius: 17px;
            color: var(--textCommonColor);
            .date-item-box {
                display: flex;
                align-items: center;
                .van-image {
                    width: 16px;
                    height: 16px;
                }
                p {
                    font-size: 16px;
                    color: var(--textCommonColor);
                    opacity: 0.9;
                }
                .date {
                    padding: 0 10px;
                    font-size: 16px;
                    font-family: 'DINOffcPro';
                    color: var(--textCommonColor);
                    opacity: 0.9;
                }
            }
        }
        .report-list-wrap {
            padding: 14px 14px 0;
            .list-item-box {
                padding-bottom: 45px;
                .item-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 22px;
                    margin-bottom: 15px;
                    color: var(--textCommonColor);
                    .label {
                        font-family: 'PingFang SC';
                        font-weight: 500;
                        font-size: 16px;
                    }
                    .amount {
                        font-family: 'DINOffcPro';
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
                .slider {
                    width: 100%;
                    height: 8px;
                    background: var(--btnBgColor);
                    border-radius: 4px;
                }
            }
        }
    }
}
</style>
